.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logo-speaking {
  animation: scale-fast 0.15s infinite, scale-normal 0.45s infinite;
}

@keyframes scale-fast {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-normal {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rangeslider {
  cursor: pointer;
  background: linear-gradient(90deg, #70dc81 0%, #f53a38 100%) !important;
  box-shadow: none !important;
}
.rangeslider__fill {
  background: transparent !important;
  box-shadow: none !important;
}
.rangeslider__handle {
  box-shadow: 0px 1px 10px 0px rgba(162, 61, 61, 0.45) !important;
}
.rangeslider__handle:after {
  background-color: white !important;
  box-shadow: none !important;
}
.react-stacked-center-carousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.react-stacked-center-carousel-slide-0 img {
  border: 3px solid #d27c27;
}
.react-stacked-center-carousel-slide-1,
.react-stacked-center-carousel-slide-2,
.react-stacked-center-carousel-slide--1,
.react-stacked-center-carousel-slide--2 {
  background-color: white;
}

.react-stacked-center-carousel-slide-1 img,
.react-stacked-center-carousel-slide--1 img {
  opacity: 0.5;
}

.react-stacked-center-carousel-slide-2 img,
.react-stacked-center-carousel-slide--2 img {
  opacity: 0.25;
}

@keyframes preloader-block {
  from {
    background-position: 0%, 0;
  }
  to {
    background-position: 170%, 0;
  }
}

.smooth-image {
  transition: opacity 1s;
  width: 100%;
  height: 100%;
}
.image-visible {
  opacity: 1;
}
.image-hidden {
  opacity: 0;
}
.smooth-preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(245, 245, 245, 0.34);
}
.smooth-preloader .loader {
  background-image: linear-gradient(to right, #e6e6e6, #ffffff, #e6e6e6);
  width: 100%;
  height: 100%;
  display: inline-block;
  background-size: 200%;
  animation: preloader-block 2s linear 0s infinite reverse forwards;
}
.smooth-image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.smooth-no-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fcfcfc;
  text-transform: capitalize;
}

.ai-interaction-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  position: relative;
}

.ai-circle {
  width: 175px;
  height: 175px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  position: relative;
}

.ai-logo {
  font-size: 50px;
  font-family: Arial, sans-serif;
  color: #992828;
}

.pulse-ring {
  position: absolute;
  width: 175px;
  height: 175px;
  border-radius: 50%;
  border: 2px solid rgba(232, 89, 41, 0.1);
  animation: waveAnimation 4s infinite cubic-bezier(0.42, 0, 0.58, 1);
  animation-delay: 0s;
}

.pulse-ring:nth-child(2) {
  width: 195px;
  height: 195px;
  animation-delay: 0.2s;
}

.pulse-ring:nth-child(3) {
  width: 215px;
  height: 215px;
  animation-delay: 0.4s;
}

.pulse-ring:nth-child(4) {
  width: 235px;
  height: 235px;
  animation-delay: 0.6s;
}

.pulse-ring:nth-child(5) {
  width: 255px;
  height: 255px;
  animation-delay: 0.8s;
}

.pulse-ring:nth-child(6) {
  width: 275px;
  height: 275px;
  animation-delay: 1s;
}

.speaking {
  filter: drop-shadow(0px 0px 3.039px #e79aaf)
    drop-shadow(0px 0px 6.077px #e79aaf) drop-shadow(0px 0px 24px #7f2f44) !important;
  animation: pulseShadow 4s infinite !important;
}

@keyframes pulseShadow {
  0% {
    filter: drop-shadow(0px 0px 3.039px #e79aaf)
      drop-shadow(0px 0px 6.077px #e79aaf) drop-shadow(0px 0px 24px #7f2f44);
  }
  25% {
    filter: drop-shadow(0px 0px 5px #e79aaf) drop-shadow(0px 0px 10px #e79aaf)
      drop-shadow(0px 0px 48px #7f2f44);
  }
  50% {
    filter: drop-shadow(0px 0px 3.039px #e79aaf)
      drop-shadow(0px 0px 6.077px #e79aaf) drop-shadow(0px 0px 24px #7f2f44);
  }
}

@keyframes waveAnimation {
  0% {
    transform: scale(1);
    opacity: 0.9;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 0.9;
  }
}

@keyframes pulseRing {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0.7;
  }
}

.progress-circle {
  width: 200px;
  height: 200px;
  border-radius: 1000px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle, #ffffff 60%, transparent 61%);
}

.progress-circle::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 10px solid transparent;
  border-top-color: rgb(123, 41, 40);
  border-right-color: rgb(123, 41, 40);
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/**
 *  loading animation
 */

.pulse {
  animation: pulse-animation 2.5s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0px 0px 15.216px 9.47px rgba(173, 93, 57, 0.27) inset,
      0 0 0 0px rgba(173, 93, 57, 0.3);
  }
  100% {
    box-shadow: 0px 0px 15.216px 9.47px rgba(173, 93, 57, 0.27) inset,
      0 0 0 50px rgba(173, 93, 57, 0);
  }
}

.rings {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

/* Individual ring styling */
.ring-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid rgba(123, 41, 40, 0.4); /* Color and opacity for rings */
  animation: ring-animation 4.9s linear infinite;
  opacity: 0;
}

/* Keyframes for ring animation */
@keyframes ring-animation {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  70% {
    opacity: 0.3;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/* Offset animations for each ring */
.ring-layer:nth-child(1) {
  animation-delay: 0s;
}
.ring-layer :nth-child(2) {
  animation-delay: 0.7s;
}
.ring-layer:nth-child(3) {
  animation-delay: 1.4s;
}
.ring-layer:nth-child(4) {
  animation-delay: 2.1s;
}
.ring-layer:nth-child(5) {
  animation-delay: 2.8s;
}
.ring-layer:nth-child(6) {
  animation-delay: 3.5s;
}
.ring-layer:nth-child(7) {
  animation-delay: 4.2s;
}

/* thinking animation */
.ai-logo {
  transition: transform 0.6s ease, opacity 0.6s ease;
  opacity: 1;
  transform: scale(1);
}

.ai-dots {
  /* transform: scale(0.1); */
  opacity: 0;
  transform: scale(0.5);
  z-index: 1;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  /* visibility: hidden; */
  transition: transform 0.6s ease, opacity 1s ease;
}

.loading-dots.show {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  height: 150px;
}

/** animation wrapper */
.animation-wrapper.loading .ai-logo {
  opacity: 0;
  transform: scale(0.5);
}

.animation-wrapper.ready .ai-logo {
  opacity: 1;
  transform: scale(1);
}

.animation-wrapper.loading .ai-dots {
  opacity: 1;
  transform: scale(1);
}

.animation-wrapper.ready .ai-dots {
  opacity: 0;
  transform: scale(0.5);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ccp-container iframe {
  display: none;
}
